import { AnimatePresence } from "framer-motion";
import Register from "pages/Auth/Register";
import Home from "pages/Home";
import Favourites from "pages/Onboarding/Favourites";
import { paths } from "./paths";
import { Routes, Route} from "react-router-dom";

const AppRoutes = () => {
  return (
    <AnimatePresence>
      <Routes>
        <Route path={paths.ROOT} element={<Home/>}/>
        <Route path={paths.GET_STARTED} element={<Register/>}/>
        <Route path={paths.SELECT_FAVOURITES} element={<Favourites/>}/>
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
