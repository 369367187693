import Api from 'utils/api';
import handleApiError from 'utils/handleApiError';

const getFavourites = async () => {
    try {
      const response = await Api.get('/favourite');
      return response.data;
    } catch (error) {
      throw new Error(handleApiError(error));
    }
};

const onboardingServices = {
    getFavourites
};
  
export default onboardingServices;