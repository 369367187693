import { extendTheme, Box } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import { motion } from "framer-motion";


export const MotionBox = motion(Box);


const breakpoints = createBreakpoints({
  xs: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
})

const colors = {
  brand: {
    primary: '#1E103E',
    secondary: '#FFD500',
    lightYellow: '#ffc41f',
    black: '#000000',
  },
}

const fonts = {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
  }

const headers = {
  fontSize: ['20px', '24px', '40px', '65px', '70px'],
  fontWeight: '700',
  letterSpacing: '-1.25px',
  lineHeight: ['20px', '24px', '40px', '65px', '70px'],
  textAlign: 'center',
  maxInlineSize: '15ch',
}

const textStyles = {
    h1: {
      ...headers
    },
    h2: {
      ...headers,
      fontSize: ['10px', '16px', '25px', '55px'],
      lineHeight: ['10px', '16px', '25px', '55px'],
      fontWeight: '700',
      maxInlineSize: '25ch',
    },
    subtitle: {
      fontSize: ['10px', '12px', '14px', '16px'],
    }
  }


export const theme = extendTheme({ colors, breakpoints, textStyles, fonts })

export const pageVariants = {
  initial: {
    opacity: 0.4,
    x: 50,
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: -50,
  }
};

export const pageTransition = {
  duration: 0.8
};

export const AppBg = {
  backgroundImage: `linear-gradient(135deg, #F4F4F4 25%, transparent 25%),linear-gradient(26deg, #F4F4F4 25%, transparent 25%),linear-gradient(324deg, #F4F4F4 25%, transparent 25%),linear-gradient(131deg, #f0f2ff 25%, #ffffff 25%)`,
  backgroundColor: "#fff",
  opacity: 0.8,
  backgroundRepeat: "repeat",
  backgroundPosition: "7px 0, 7px 0, 0 0, 0 0",
  backgroundSize: `${7}px 7px`,
}