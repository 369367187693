import { Text, Box, Container, VStack, Center } from "@chakra-ui/react";
import { ReactComponent as Thinking } from "assets/thinking.svg";
import { ReactComponent as HandWave } from "assets/handWave.svg";
import { ReactComponent as Organize } from "assets/organize.svg";
import { ReactComponent as SaveTime } from "assets/saveTime.svg";
import { HomeComponents } from "components/HomePage";
import { useViewportScroll } from "framer-motion";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

const { floatVariant, MotionBox, food, StyledCard, SlideupContent, AppGrid } =
  HomeComponents;

const Home = () => {
  const { scrollY } = useViewportScroll();
  console.log(scrollY);
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(135deg, #F4F4F4 25%, transparent 25%),linear-gradient(26deg, #F4F4F4 25%, transparent 25%),linear-gradient(324deg, #F4F4F4 25%, transparent 25%),linear-gradient(131deg, #f0f2ff 25%, #ffffff 25%)`,
        backgroundColor: "#fff",
        opacity: 0.8,
        backgroundRepeat: "repeat",
        backgroundPosition: "7px 0, 7px 0, 0 0, 0 0",
        backgroundSize: `${7}px 7px`,
      }}
    >
      <Box
        pt={20}
        px={[12, 10, 5, 0]}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <MotionBox
          animate={{ y: [0, 20, 0, 10, 0], rotate: [0, 20, 0] }}
          transition={{ duration: 6, repeat: Infinity, delay: 0.5 }}
          mb={10}
        >
          <HandWave />
        </MotionBox>
        <Text
          bgGradient="linear(to-l,  #4F0F87, #7928CA, #FF0080, #0bc5ea)"
          bgClip="text"
          fontWeight="extrabold"
          textStyle="h1"
        >
          Use fudtable
        </Text>
        <Text color="brand.primary" textStyle="h1">
          Stop worrying about your next meal.
        </Text>

        <MotionBox
          whileTap={{ scale: 1.5 }}
          whileHover={{ scale: 1.2 }}
          fontWeight="bold"
          fontSize={20}
          mt={10}
          p={15}
          as="button"
          borderRadius="lg"
          bg="brand.lightYellow"
          color="brand.primary"
          display="flex"
          alignItems="baseline"
          onClick={() => navigate("/get-started")}
        >
          <Box>Get Started</Box>
          <Box ml={1}>
            <ArrowForwardIcon />
          </Box>
        </MotionBox>
      </Box>

      <Container maxW="container.xl">
        <AppGrid>
          <SlideupContent replay={true}>
            <Text fontSize={{ base: 16, md: 20 }} color="brand.primary">
              Welcome to Fudtable - the ultimate food timetable app that helps
              you eat better, feel better, and live better! Whether you're
              looking to lose weight, improve your health, or just maintain a
              balanced diet, Fudtable has got you covered.
              So why wait? Try Fudtable today and start your journey to a healthier, happier you!
            </Text>
          </SlideupContent>
          <SlideupContent replay={true}>
            <Thinking style={{ width: "100%", height: "100%" }} />
          </SlideupContent>
          {food.map(([emoji, hueA, hueB, direction, rotate]) => (
            <StyledCard
              emoji={emoji}
              hueA={hueA}
              rotate={rotate}
              direction={direction}
              hueB={hueB}
              key={emoji}
            />
          ))}
        </AppGrid>
        <MotionBox
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }}
          variants={floatVariant}
          display="flex"
          justifyContent="space-between"
          px="20%"
        >
          {Array.from(new Array(2)).map((_, idx) => (
            // <div>
            // <StyledIcon Emoji={PartyPopper} size={50} />
            <div></div>
          ))}
        </MotionBox>

        <Box mt={20} display="grid" placeItems="center">
          <MotionBox
            initial="offscreen"
            whileInView="onscreen"
            fontWeight="extrabold"
            my={15}
            viewport={{ once: false }}
            variants={{
              offscreen: {
                y: 100,
              },
              onscreen: {
                y: 0,
                transition: {
                  duration: 2,
                },
                opacity: [0, 0.2, 0.4, 0.8, 1],
              },
            }}
          >
            <Text color="brand.primary" textStyle="h2">
              Just tell us what you like
            </Text>
          </MotionBox>

          <MotionBox
            initial="offscreen"
            whileInView="onscreen"
            fontWeight="extrabold"
            mb={15}
            viewport={{ once: false }}
            variants={{
              offscreen: {
                y: 100,
              },
              onscreen: {
                y: 0,
                transition: {
                  duration: 3,
                },
                opacity: [0, 0.2, 0.4, 0.5, 0.8, 0.9, 1],
              },
            }}
          >
            <Text color="brand.primary" textStyle="h2">
              We will take care of the rest.
            </Text>
          </MotionBox>
        </Box>
        <AppGrid>
          <SlideupContent>
            <Organize style={{ width: "100%", height: "100%" }} />
          </SlideupContent>
          <SlideupContent>
            <Text fontSize={{ base: 16, md: 20 }} color="brand.primary">
              Our app generates personalized food timetables based on your
              health, age, and lifestyle. Simply input your preferences and let
              Fudtable do the rest! With weekly, monthly, and yearly plans
              available, you can choose the one that best fits your needs and
              goals.
            </Text>
          </SlideupContent>
          <SlideupContent>
            <Text fontSize={{ base: 16, md: 20 }} color="brand.primary">
              But Fudtable is more than just a food timetable app - it's also a
              one-stop shop for all your dining needs. With our partnerships
              with top restaurants, you can have your selected Fudtable meals
              automatically prepared and delivered to you, saving you time and
              hassle. Plus, you can gift Fudtable timetable bundles to friends
              and loved ones, making it easy to share the benefits of healthy
              eating.
            </Text>
          </SlideupContent>
          <SlideupContent>
            <SaveTime style={{ width: "100%", height: "100%" }} />
          </SlideupContent>
        </AppGrid>
      </Container>
      <Box
        mt={30}
        padding={{ base: 10, lg: 20 }}
        width="100%"
        bg="brand.primary"
        color="white"
      >
        <Box
          display="grid"
          placeItems={{ base: "flex-start", md: "center" }}
          gridTemplateColumns={{
            base: "1fr",
            md: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          rowGap={10}
        >
          <Box>
            <VStack spacing={2} align="left">
              <Text>Sign up</Text>
              <Text>contact</Text>
              <Text>careers</Text>
              <Text>feedback</Text>
            </VStack>
          </Box>
          <Box>
            <VStack spacing={2} align="left">
              <Text>Partners</Text>
              <Text>Blog</Text>
              <Text>Roadmap</Text>
              <Text>Team</Text>
            </VStack>
          </Box>
          <Box>
            <VStack spacing={2} align="left">
              <Text>Research</Text>
              <Text>people</Text>
              <Text>Industry</Text>
              <Text>Supply</Text>
            </VStack>
          </Box>
        </Box>
        <Box mt={20}>
          <Center>
            <Text fontWeight="bold">Fudtable {new Date().getFullYear()}</Text>
          </Center>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
