import { useState } from "react";
import { Container, Text, Box } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { useAnimation } from "framer-motion";
import { useQuery } from "react-query";
import { MotionBox, AppBg } from "theme";
import { ReactComponent as LikeImage } from "assets/fav_like.svg";
import AppButton from "Reuseables/AppButton";
import onboardingServices from "services/onboardingServices";

const bgcolors = [
  "#958EE6",
  "#274DAE",
  "#EB5757",
  "#F2994A",
  "#2D9CDB",
  "#91BE32",
  "#F75A5A",
  "#2735AE",
  "#7928CA",
  "#5070E0",
  "#4F0F87",
  "#FF0080",
  "#0bc5ea",
];
const favourites = [
  "rice",
  "beans",
  "yam",
  "pasta",
  "garri",
  "melons",
  "rice",
  "beans",
  "yam",
  "pasta",
  "garri",
  "melons",
  "rice",
  "beans",
  "yam",
  "pasta",
  "garri",
  "melons",
  "rice",
  "beans",
  "yam",
  "pasta",
  "garri",
  "melons",
  "rice",
  "beans",
  "yam",
  "pasta",
  "garri",
  "melons",
  "rice",
  "beans",
  "yam",
  "pasta",
  "garri",
  "melons",
];

const Favourites = () => {

  useQuery(['favourites'], onboardingServices.getFavourites, {
    onSuccess: (data) => {
      console.log(data)
    }
  })
  const [favMap, setFavMap] = useState(() =>
    favourites.map((fav) => ({
      name: fav,
      isSelected: false,
      bg: bgcolors[Math.floor(Math.random() * bgcolors.length)],
    }))
  );

  const countControl = useAnimation();

  const selectedFavs = favMap.filter((fav) => fav.isSelected === true);

  const toggleSelections = (idx) => {
    if (selectedFavs.length > 4) {
      countControl.start({
        scale: selectedFavs.length > 9 ? [1.4, 2, 1] : [1.4, 1.8, 1],
        x: selectedFavs.length > 9 && [-30, 20, -30, 20, 0],
        transition: {
          type: "spring",
          velocity: selectedFavs.length * 10,
          stiffness: selectedFavs.length * 100,
          damping: selectedFavs.length * 10,
          duration: 0.7,
        },
      });
    }
    const favCopy = [...favMap];
    selectedFavs.length > 9
      ? (favCopy[idx].isSelected = false)
      : (favCopy[idx].isSelected = !favCopy[idx].isSelected);
    setFavMap(favCopy);
  };

  return (
    <Box sx={AppBg} height="100%">
      <Container maxW="container.xl">
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Box display="flex" alignItems="center">
              <Box my={20}>
                <Text align="start" color="brand.primary" textStyle="h1">
                  Select your favourites.
                </Text>
                <Text mt={4} fontWeight="bold" color="brand.primary" textStyle="subtitle">
                  You can select a maximum of ten items
                </Text>
              </Box>
              <MotionBox
                sx={{ textAlign: "center" }}
                animate={countControl}
                p={13}
                width={50}
                height={50}
                fontWeight="bolder"
                fontSize={20}
                color="white"
                borderRadius="50%"
                bg={selectedFavs.length > 8 ? "#FF0080" : "#91BE32"}
              >
                {selectedFavs.length}
              </MotionBox>
            </Box>

            <Box display="flex" flexWrap="wrap" maxW={700}>
              {favMap.map(({ name, bg, isSelected }, idx) => (
                <MotionBox
                  whileTap={{
                    scale: isSelected ? 0.2 : 2.5,
                    rotate: isSelected ? -60 : 60,
                  }}
                  transition={{ duration: 0.7 }}
                  key={idx}
                  as="button"
                  sx={{
                    border: `1px solid ${bg}`,
                  }}
                  borderRadius="lg"
                  bg={isSelected ? bg : "transparent"}
                  color={isSelected ? "white" : bg}
                  fontWeight="bold"
                  height={{base: 5, md: 10}}
                  px={{base: 5, md: 10}}
                  fontSize={{base: 12, md: 16}}
                  mb={3}
                  mr={3}
                  width="fit-content"
                  onClick={() => toggleSelections(idx)}
                >
                  {name}
                </MotionBox>
              ))}
            </Box>

            <Box my={10} display="flex" justifyContent="end">
              <AppButton
                isLoading={false}
                loadingText="Hold on"
                mt={10}
                size="lg"
                rightIcon={<ArrowForwardIcon />}
                bg="brand.lightYellow"
                type="submit"
              >
                Continue
              </AppButton>
            </Box>
          </Box>

          <Box display={{base: 'none', lg: 'block'}} width="40%">
            <LikeImage style={{ width: "100%", height: "100%" }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Favourites;
