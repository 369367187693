import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Box,
  VStack,
  Text,
  Flex,
  useToast
} from "@chakra-ui/react";
import { ArrowForwardIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import PropTypes from 'prop-types'; 
import { useMutation } from "react-query";
import authServices from "services/authServices";



const RegisterForm = ({toggleAuthState}) => {
  const toast = useToast()
  const [showPassword, setShowPassword] = useState(false);

  const { mutate : signup, isLoading } = useMutation(authServices.signUp, {
    onSuccess: ({message}) => {
      toast({
        title: 'Login to continue',
        description: message,
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
      toggleAuthState()
    },
    onError: ({message}) => {
      toast({
        title: 'Error',
        description: message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
    }
  })
  
  const onSubmit = (values) => {
    console.log(values);
    signup({...values})
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4}>
        <FormControl isInvalid={errors.email}>
          <FormLabel fontWeight="bold" htmlFor="email">Email</FormLabel>
          <Input
            type="email"
            size="lg"
            id="email"
            placeholder="example@mail.com"
            focusBorderColor="brand.primary"
            {...register("email", {
              required: "Email is required",
            })}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password}>
          <FormLabel fontWeight="bold" htmlFor="password">Password</FormLabel>
          <InputGroup size="lg">
            <Input
              size="lg"
              id="password"
              placeholder="********"
              type={showPassword ? "text" : "password"}
              focusBorderColor="brand.primary"
              {...register("password", {
                required: "password is required",
              })}
            />
            <InputRightElement width="4.5rem">
              <Box type="button" as="button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
              </Box>
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        </VStack>
        <Flex justify="flex-end" mt={5}>
             <Text fontSize={{base: 14, md: 16}}  mr={2} align="right" color="brand.primary">Already have an account? </Text>
             <Button disabled={isLoading} fontSize={{base: 14, md: 16}} onClick={() => toggleAuthState()} _focus={{boxShadow: 0}} color="brand.primary" variant="link">Login</Button>
            </Flex>
        <Box display="flex" justifyContent="flex-end">
        <Button isLoading={isLoading} loadingText="Hold on"  size="lg" rightIcon={<ArrowForwardIcon/>} mt={6} bg="brand.lightYellow" type="submit">
          Sign up
        </Button>
        </Box>
      </form>
    </Box>
  );
};

RegisterForm.propTypes = {
  toggleAuthState: PropTypes.func
}

export default RegisterForm;
