import { Button } from '@chakra-ui/react'
import React from 'react'

const AppButton = ({children, ...rest}) => {
  return (
    <Button _focus={{boxShadow: 0}} {...rest}>
        {children}
    </Button>
  )
}

export default AppButton