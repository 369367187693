import { Box, Icon, useMediaQuery } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ReactComponent as Avocado } from "assets/Avocado.svg";

const MotionBox = motion(Box);
const clipPath = `path(
  "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
)`;

const hue = (h) => `hsl(${h}, 100%, 50%)`;
const floatVariant = {
  offscreen: {
    y: 80,
    opacity: 0,
  },
  onscreen: {
    y: -500,
    transition: {
      type: "spring",
      bounce: 0.25,
      duration: 9,
    },
    opacity: [0, 0.1, 0.2, 0.6, 0.5, 0, 1, 0.4, 0],
  },
};

const HomeCard = ({ children }) => {
  return (
    <Box
      fontSize={{ base: 16, md: 20 }}
      color="brand.primary"
      width={{ base: "80%" }}
      height="430px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      background="white"
      borderRadius="20px"
      padding={{ base: 10 }}
      boxShadow="lg"
    >
      {children}
    </Box>
  );
};

const AppGrid = ({ children }) => {
  return (
    <Box
      display="grid"
      placeItems="center"
      gridTemplateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
      gridRowGap={20}
      px={{ base: 0, lg: "50px" }}
      my={20}
    >
      {children}
    </Box>
  );
};

const StyledIcon = ({ Emoji, size }) => {
  return (
    <MotionBox>
      <Icon boxSize={{ base: 50, lg: size || 60 }}>
        <Emoji />
      </Icon>
    </MotionBox>
  );
};

const SlideupContent = ({ children, replay = false }) => {
  return (
    <MotionBox
      alignSelf="center"
      justifySelf="center"
      initial="offscreen"
      whileInView="onscreen"
      fontWeight={600}
      fontSize={18}
      color="brand.primary"
      width={{ base: "80%" }}
      viewport={{ once: replay }}
      variants={{
        offscreen: {
          y: 100,
        },
        onscreen: {
          y: 0,
          transition: {
            duration: 3,
          },
          opacity: [0, 0.4, 0.8, 1],
        },
      }}
    >
      {children}
    </MotionBox>
  );
};

const StyledCard = ({ emoji, hueA, hueB, direction, rotate }) => {
  const [isSmallScreen] = useMediaQuery("(min-width: 520px)");

  const background = `linear-gradient(306deg, ${hue(hueA)}, ${hue(hueB)})`;

  return (
    <MotionBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="relative"
      pt="20px"
      mb="-120px"
      width={{ base: "95%", md: "80%" }}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: false, amount: 0.6 }}
    >
      <Box
        transform={direction}
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        clipPath={clipPath}
        background={background}
        display={{ base: "none", md: "block" }}
      />
      <MotionBox
        fontSize={{ base: 14, md: 17, xl: 20 }}
        color="brand.primary"
        width={{ base: "80%" }}
        height="430px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        background="white"
        borderRadius="20px"
        marginBottom="50px"
        padding={{ base: 5, md: 10 }}
        boxShadow="lg"
        variants={{
          offscreen: {
            y: 20,
            x: 0,
            rotate,
          },
          onscreen: {
            y: 60,
            x: isSmallScreen && (rotate > 0 ? -60 : 60),
            rotate: 0,
            transition: {
              type: "spring",
              bounce: 0.5,
              duration: 4,
            },
          },
        }}
      >
        {emoji}
      </MotionBox>
    </MotionBox>
  );
};

const cardText = `People who eat a healthy diet are more likely to be physically active,
 have a healthy body weight, and lower their risk of developing chronic diseases like diabetes and heart disease. 
 With Fudtable, you can easily monitor and track your food intake 
 to ensure that you're getting the nutrients your body needs.`;

const food = [
  [<StyledIcon Emoji={Avocado} />, 340, 10, "scaleX(1)", -10],
  [cardText, 20, 40, "scaleX(-1)", 10],
];

export const HomeComponents = {
  floatVariant,
  MotionBox,
  food,
  cardText,
  StyledCard,
  SlideupContent,
  StyledIcon,
  AppGrid,
  hue,
  clipPath,
  HomeCard,
};
