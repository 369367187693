import { Container, Box, Text, useMediaQuery } from "@chakra-ui/react";
import { MotionBox, pageTransition, pageVariants } from "theme";
import { ReactComponent as SignUpIllustration } from "assets/signup.svg";
import { ReactComponent as LoginIllustration } from "assets/login.svg";
// import BG from "assets/fly.svg";
import RegisterForm from "./RegisterForm";
import LoginForm from "./LoginForm";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Auth from "utils/auth";

const Register = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [hideIllustration] = useMediaQuery('(min-width: 960px)')
  // const [thinBorder] = useMediaQuery('(min-width: 430px)')
  const navigate = useNavigate()

  useEffect(() => {
    if(Auth.isAuthenticated()){
     navigate("/favourites")
    }
  // eslint-disable-next-line
  }, [])
  

  const toggleAuthState = () => {
    setIsSignUp(!isSignUp);
  };

  const PresenceBox = ({ children }) => {
    return (
      <MotionBox
        initial={{ opacity: 0, x: -100, scale: 0.2 }}
        animate={{ opacity: 1, x: 0, scale: 1 }}
        exit={{ opacity: 0, x: 10 }}
        transition={{ duration: 1 }}
      >
        {children}
      </MotionBox>
    );
  };

  return (
    <MotionBox
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
      minHeight="100vh"
      display="grid"
      placeItems="center"
      sx={{
        backgroundImage: `repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 7px ), repeating-linear-gradient( #F4F4F4, #fff )`,
        opacity: 0.1,
        backgroundColor: `#fff`
      }}
      // sx={{ background: `#F4F4F4 url(${BG}) center 100% no-repeat`}}
    >
      <Container maxW="container.xl" centerContent>
        <MotionBox
          initial={{ opacity: 0, y: 25 }}
          animate={{ opacity: 1, y: 0}}
          exit={{ opacity: 0, x: 0 }}
          transition={{ duration: 0.9 }}
          // sx={{ border: `${thinBorder ? 6 : 2}px solid #1E103E`}}
          display="grid"
          maxW={800}
          gridTemplateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
          alignItems="start"
          alignContent="center"
          gridGap={10}
          bg="white"
          boxShadow="xl"
          borderRadius="lg"
          width="100%"
          p={{ base: 5, md: 20 }}
        >
          <AnimatePresence>
            <Box>
              <Text
                color="brand.primary"
                fontWeight="extrabold"
                textStyle="h2"
                align="left"
                mb={10}
              >
                <PresenceBox>
                {isSignUp ? "Sign up" : "Login"}
                </PresenceBox>
              </Text>
              <PresenceBox>
                {isSignUp ? (
                  <RegisterForm toggleAuthState={toggleAuthState} />
                ) : (
                  <LoginForm toggleAuthState={toggleAuthState} />
                )}
              </PresenceBox>
            </Box>
            {hideIllustration && 
            <Box align-Self="end">
              <PresenceBox>
                {isSignUp ? (
                  <SignUpIllustration
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <LoginIllustration
                    style={{ width: "100%", height: "100%" }}
                  />
                )}
              </PresenceBox>
            </Box>
            }
          </AnimatePresence>
        </MotionBox>
      </Container>
    </MotionBox>
  );
};

export default Register;
