import Api from 'utils/api';
import handleApiError from 'utils/handleApiError';
import Auth from 'utils/auth';


const signUp = async (data) => {
  try {
    const response = await Api.post('/auth/signup', data);
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error));
  }
};

const login = async (data) => {
  try {
    const response = await Api.post('/auth/login', data);
    return response.data;
  } catch (error) {
    throw new Error(handleApiError(error));
  }
};

const refreshAccessToken = async () => {
    try {
      const refreshToken = Auth.getRefreshToken();
      const {data} = await Api.post("/auth/refresh", { refreshToken });
      Auth.setToken(data.accessToken);
      Auth.setRefreshToken(data.refreshToken);
      return data.accessToken;
    } catch (e) {
      throw new Error(handleApiError(e));
    }
  };

const authServices = {
  login,
  signUp,
  refreshAccessToken
};

export default authServices;
